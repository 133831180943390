@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Poppins:wght@200;300;400;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* --------------------------------- */

header {
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 3rem;
    align-items: center;
}

#cetys-logo {
    height: 4rem;
}

#search-icon {
    height: 1rem;
    margin-right: .8rem;   
}

#user-icon {
    height: 1rem;
    margin-right: .9rem;
}

#user-img {
    height: 2rem;
    margin-right: .9rem;
    border-radius: 50px;
}

.right-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right-box .inner-box {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding: .5rem .2rem;
    border-radius: 5px;
    transition: all ease .3s;
    color: grey;
}

#log-in {
    display: flex;
    align-items: center;
    padding: .4rem 1rem;
    border: none;
    background-color: transparent;
    transition: all ease .3s;
    border-radius: 5px;
    font-family: 'poppins';
    font-size: medium;
}

#log-in:hover {
    cursor: pointer;
    background-color: rgb(226, 225, 225);
}

#log-out {
    display: flex;
    align-items: center;
    padding: .4rem 1rem;
    border: none;
    background-color: transparent;
    transition: all ease .3s;
    border-radius: 5px;
    font-family: 'poppins';
    font-size: medium;
}

#log-out:hover {
    cursor: pointer;
    background-color: rgb(226, 225, 225);
}

#search-bar {
    width: 0;
    border: none;
    background-color: transparent;
    transition: all ease .5s;
    font-size: medium;
}


.right-box .inner-box #search-bar:focus {
    outline: none;
    width: 10rem;
}

.inner-box:focus-within {
    background-color: rgb(226, 225, 225);
    padding: .5rem 1rem;
}

.right-box .inner-box button {
    border: none;
    background-color: transparent;
}

.right-box .inner-box button:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    header {
      flex-direction: column;
      padding: 1rem 0;
    }
    
    .right-box {
        justify-content: space-between;
        width: 100%;
        padding: 0 1rem;
    }

    .right-box .inner-box #search-bar:focus {
        outline: none;
        width: 6rem;
        transition: all ease .3s;
    }
    .right-box .inner-box{
        margin-left: .5rem;
        transition: all ease .3s;
    }

    .right-box .inner-box:hover #search-bar{
        width: 6rem;
        transition: all ease .3s;
    }

    .upper-mobile-head {
        display: flex;
        justify-content: space-between;
        margin-bottom: .5rem;
        width: 100%;
        padding: 0 1.8rem;
        align-items: center;
    }
  }