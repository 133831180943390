.main-proydisplay #title {
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 0.08em;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.main-proydisplay {
    margin-bottom: 8rem;
}

.main-scroll {
    display: flex;
    justify-content: space-around;
}

.scroll-proys {
    height: 25vh;
    overflow-y: hidden;
    overflow-y: scroll;
    width: 40%;
}

.maped-proy:hover {
    cursor: pointer;
}

.maped-proy {
    transition: all ease .3s;
}

.selected-proy-scroll {
    background-color: #FFC122;
    transition: all ease .3s;
    border-radius: 10px;
    font-size: larger;
    padding: .3rem;
}

.selected-proy-scroll:hover {
    cursor: pointer;
}

.selected-proy {
    height: auto;
}

.selected-proy .upper-box {
    display: flex;
    justify-content: space-around;
    align-items: end;
}

.selected-proy .inner-middle {
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    width: 50%;
    padding: 2.5rem;
    justify-content: space-between;
    border-radius: 15px;
}

.selected-proy .inner-middle .inner-upper-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.selected-proy .inner-middle .inner-upper-box .inner-left-box {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.selected-proy .inner-middle .inner-bottom-box {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}


.selected-proy .inner-middle .inner-right-box {
    width: 60%;
    padding-left: 1rem;
    text-align: justify;
}

.selected-proy .objs {
    display: flex;
    flex-wrap: wrap;
}


.main-objs {
    display: flex;
    justify-content: space-around;
    margin-top: 1.5rem;
}

.main-objs .objs {
    width: 80%;
    gap: 1.2rem;
    display: flex;
    justify-content: space-between;
}

.obj-unactive {
    filter: grayscale(1);
}

.main-objs .objs:hover {
    cursor: pointer;
}

.obj-unactive:hover {
    cursor: pointer;
}

.bottom-desc {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 3rem;
}

.bottom-desc .inner-bottom-desc {
    width: 40%;
    background-color: #F5F5F5;
    padding: 2rem;
    margin: 2rem;
    height: auto;
    border-radius: 10px;
    letter-spacing: 1px;
    font-weight: 400;
}

.main-proydisplay .obj-desc {
    width: 100%;
    height: 8rem;
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
}

.main-proydisplay .obj-desc .obj-desc-content {
    display: flex;
    width: 50%;
    min-height: 1rem;
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: .8rem;
}


.main-proydisplay .pop-info {
    position: absolute;
}

.main-proydisplay .selected-proy .links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    width:8rem;
}

.main-proydisplay .selected-proy .links button {
    background-color: #FFC122;
    border: none;
    height: 45px;
    width: 45px;
    border-radius: 50px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.main-proydisplay .selected-proy .links button:hover {
    cursor: pointer;
    scale: 1.05;
}


.main-proydisplay .inner-left-box h2 {
    font-size: 1.5rem;
}

.main-proydisplay #message {
    size: large;
    margin-top: 2rem;
    color: grey;
}

.main-proydisplay .bottom-trigger {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

@media screen and (max-width: 768px) {

    .selected-proy .inner-middle {
        width: 90%;
    }

    .selected-proy .inner-left-box h2 {
        font-size: 1rem;
    }

    .selected-proy .inner-right-box p {
        font-size: .8rem;
    }

    .selected-proy .inner-right-box {
        padding:0 .7rem;
    }

    .main-proydisplay .scroll-proys {
        width: 75%;
    }

    .inner-upper-box .inner-left-box .links {
        width:6rem;
    }

    .main-proydisplay .obj-desc {
        margin-top: 10rem;
        height: 9rem;
        display: flex;
        justify-content: space-around;
        padding-left: 0;
    }
    
    .main-proydisplay .obj-desc .obj-desc-content {
        width: 90%;
    }

}