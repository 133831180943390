@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Poppins:wght@200;300;400;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* --------------------------------- */

.categories-main .cat:hover {
    cursor: pointer;
}

.categories-main #title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 5.5rem;
    padding-top: 5rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-around;
}

.categories-main .description {
    display: flex;
    justify-content: space-around;
    background-color: black;
    color: white;
    text-align: left;
    padding: 8.3rem 0;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 1rem;
}





.categories-main .cat0 {
    display: flex;
    justify-content: space-between;
    transition: all ease .3s;
}

.categories-main .cat0 .right-box {
    width: 55%;
    display: flex;
    flex-direction: column;

}

.categories-main .cat0 .left-box {
    width: 45%;
}

.categories-main .cat0 .upper-box {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 4rem;
    text-align: right;
    padding: 1.5rem;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    transition: all ease .3s;
}

.categories-main .cat0 .bottom-box {
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem 2rem 0;
    font-weight: 600;
    width: 100%;
}

.categories-main .pre-alfa .left-box {
    background-image: url('https://i.ibb.co/sC7MgJV/bg-cat-1.jpg');
    background-size: contain;

}

.categories-main .cat0 .left-box {
    width: 45%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80rem;
}










.categories-main .cat {
    height: 38vh;
}

.categories-main .cat1 {
    display: flex;
    justify-content: space-between;
    transition: all ease .3s;
}

.categories-main .cat1 .left-box {
    width: 55%;
}

.categories-main .cat1 .upper-box {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 4rem;
    text-align: left;
    padding: 1.5rem;
}

.categories-main .cat1 .bottom-box {
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem 2rem 0;
    font-weight: 600;
}

.categories-main .cat1 .right-box {
    width: 45%;
    background-repeat: no-repeat;
    background-position: -40rem center;
    background-size: 90rem;
}

.categories-main .academica .right-box {
    background-image: url('https://i.ibb.co/cv3njf6/Rectangle-87.png');
    background-size: cover;
    background-position: center;
}


.categories-main .cat2 {
    display: flex;
    justify-content: space-between;
    transition: all ease .3s;
}

.categories-main .cat2 .right-box {
    width: 55%;
    display: flex;
    flex-direction: column;

}

.categories-main .cat2 .left-box {
    width: 45%;
}

.categories-main .cat2 .upper-box {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 4rem;
    text-align: right;
    padding: 1.5rem;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    transition: all ease .3s;
}

.categories-main .cat2 .bottom-box {
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem 2rem 0;
    font-weight: 600;
    width: 100%;
}

.categories-main .intermedio .left-box {
    background-image: url('https://i.ibb.co/0FWYVkq/pexels-sameer-kalani-3802602-4-1.jpg');
    background-size: contain;

}

.categories-main .cat2 .left-box {
    width: 45%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80rem;
}

.categories-main .avanzado .bottom-box {
    text-align: start;
    justify-content: flex-start;
    padding: 0 0 2rem 2rem;
}

.categories-main .avanzado .right-box {
    background-image: url('https://i.ibb.co/b6XCNbb/E4-B3-F261-29-B0-4-E23-B048-E766540-D9733-1-201-a-1.png');
}

.categories-main .avanzado .right-box {
    width: 45%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50rem;
}

.categories-main .cat:hover .top {
    color: white;
    transition: all ease .3s;
}

.categories-main .cat:hover .bottom {
    color: #614600;
    transition: all ease .3s;
}

.categories-main .cat:hover {
    background-color: #FFC122;
    transition: all ease .3s;
}


@media screen and (max-width: 768px) {
    .categories-main #title {
        font-size: 1.5rem;
    }

    .categories-main .description {
        display: flex;
        justify-content: space-around;
        background-color: black;
        color: white;
        text-align: left;
        padding: 4rem 2rem;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: .8rem;
    }

    .categories-main .cat .upper-box{
        font-size: 1.5rem;
    }

    .categories-main .cat1 .bottom-box {
        display: flex;
        justify-content: flex-start;
        padding-left: 1.5rem;
        font-weight: 600;
    }

    .categories-main .cat1 .left-box {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}