.main-eval .eval-button {
    width: 50%;
    padding: .7rem;
    border-radius: 50px;
    border: none;
    background-color: #FFC122;
    font-weight: 800;
    font-size: large;
    transition: all ease .3s;
    margin-bottom: 1rem;
}

.main-eval .eval-button:hover {
    cursor: pointer;
    scale: 1.03;
    transition: all ease .3s;
}

.main-eval {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-eval .form-head {
    display: flex;
    justify-content: flex-end;
}
.main-eval .form-head button {
    margin: .5rem;
    border: none;
    background-color: rgb(40, 40, 40);
    border-radius: 10px;
    padding: .3rem;
    color: white;
}

.main-eval .form-head button:hover {
    scale: 1.02;
    cursor: pointer;
}

.main-eval .eval-form {
    background-color: black;
    border-radius: 10px;
    width: 50%;
    color: white;
    display: flex;
    flex-direction: column;
}

.main-eval .main-eval-q {
    display: flex;
    flex-direction: column;
}

.main-eval .main-eval-q .eval-maped {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    color: #FFC122;
    font-weight: 800;
    align-items: center;
}

.main-eval .main-eval-q .eval-maped .desc {
    width: 75%;
    text-align: left;
}

.main-eval .main-eval-q .eval-maped input {
    width: 10%;
    padding: .3rem;
    border-radius:10px;
    border: none;
}

.main-eval .title {
    margin: 1rem 0;
    padding: .8rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-eval .title p {
    margin-bottom: .3rem;
    font-size: x-small;
}

.main-eval .title h3 {
    margin-bottom: .5rem;
    font-size: small;
    margin-top: .5rem;
}

.main-eval .enviar {
    margin: 1rem 0;
}

.main-eval .enviar button {
    width: 40%;
    border:none;
    border-radius: 50px;
    padding: .7rem;
    background-color: #FFC122;
    color: white;
    font-weight: 800;
    transition: all ease .3s;
}

.main-eval .enviar button:hover {
    cursor: pointer;
    scale: 1.03;
    transition: all ease .3s;
}

.eval-maped .left-desc {
    display: flex;
    flex-direction: column;
}

.eval-maped .left-desc .inner-desc {
    color: white;
    font-size: x-small;
    text-align: start;
    font-weight: 400;
}

@media screen and (max-width: 768px) {

    .main-eval .eval-form {
        width: 90%;
    }

    .main-eval .eval-button {
        width: 90%;
    }

}