@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Poppins:wght@200;300;400;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* --------------------------------- */

.section-main {
    width: 100%;
    display: flex;
    justify-content: space-around;
    height: 3rem;
    margin: 1rem 0;
}

.section-main .inner-box {
    width: 50%;
    display: flex;
    justify-content: space-between;
    font-family: 'poppins';
    font-size: larger;
    font-weight: 600;
}

.section-main .inner-box span {
    transition: all ease .3s;
}

.section-main .inner-box span:hover {
    font-size: x-large;
    cursor: pointer;
    color: #F5C235;
}

@media screen and (max-width: 768px) {
    .section-main .inner-box {
        font-size: small;
        width: 70%;
    }

    .section-main .inner-box span:hover {
        font-size: large;
        cursor: pointer;
        color: #F5C235;
    }
}