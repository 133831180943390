@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Poppins:wght@200;300;400;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* --------------------------------- */

footer {
    display: flex;
    flex-direction: column;
    font-family: 'Poppins';
}

footer .container {
    padding: 2rem 4rem 1rem 4rem;
}

footer .title {
    text-align: left;
    font-size: 20px;
    margin-bottom: 2rem;
}

footer .inner-input {
    display: flex;
    align-items: center;
    padding: .3rem .5rem;
    border-bottom: solid 2px black;
    justify-content: space-between;
    width: 35%;
    transition: all ease .3s;
}


footer .inner-input #email {
    border: none;
    font-size: large;
    background-color: transparent;
}

.inner-input:focus-within {
    background-color: rgb(226, 225, 225);
}

#email:focus {
    outline: none;
}

footer #email-icon {
    height: 24px;
}

footer button {
    background-color: transparent;
    border: none;
}

footer button:hover {
    cursor: pointer;
}

footer .inside-footer {
    background-color: #E9E9EA;
    padding: 1rem;
}

footer .bottom-box {
    display: flex;
    margin-top: 1.69rem;
    justify-content: flex-end;
    align-items: center;
}

footer .bottom-box .links {
    display: flex;
    margin-right: 6rem;

}

footer .bottom-box .links button {
    margin-right: 1rem;
}

footer .bottom-box .links button img {
    height: 2rem;
}

/* Mobile Section */

@media screen and (max-width: 768px) {

    footer .container {
        padding: 1rem;
    }

    footer .bottom-box {
        display: flex;
        margin-top: 1.69rem;
        justify-content: space-between;
        align-items: center;
    }

    footer .bottom-box .links {
        display: flex;
        margin-right: 0;
    }
 
    footer {
        font-size: .8rem;
    }

    footer .container .title {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .container .upper-content {
        justify-content: space-between;
        align-items: center;
    }

    .upper-content .inner-input #email {
        font-size: .8rem;
    }

    footer .inner-input {
        display: flex;
        width: 100%;
        align-items: center;
        border-bottom: solid 2px black;
        justify-content: space-between;
        transition: all ease .3s;
    }

    footer #email-icon {
        height: 24px;
    }

}