.pu-button {
    border: none;
    border-radius: 50px;
    padding: 10px;
    background-color: rgb(216, 216, 216);
    transition: all ease .3s;
}

.pu-button img {
    height: 25px;
    filter: opacity(.33);
}

.pu-button:hover {
    scale: 1.05;
    transition: all ease .3s;
    cursor: pointer;
    background-color: rgb(196, 196, 196);
}


/* Content main */

.popup-content {
    border-radius: 10px;
    background-color: grey;
    font-family: 'poppins';
}

.pop-main form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    
}

.pop-main .upper-box {
    display: flex;
    flex-direction: column;
}

.pop-main .upper-box label {
    display: flex;
    justify-content: space-between;
}

.pop-main .input-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.pop-main .input-box input {
    width: 70%;
    border: none;
    border-radius: 50px;
    background-color: rgb(237, 237, 237);
    padding: 0 .8rem;
}

.pop-main .bottom-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.pop-main .bottom-box button {
    border: none;
    border-radius: 50px;
    background-color: #f8c446;
    padding: .7rem 1rem;
    transition: all ease .3s;
}

.pop-main .bottom-box button:hover {
    scale: 1.05;
    transition: all ease .3s;
    cursor: pointer;
}